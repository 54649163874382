<template>
	<div class="form_ct_box" v-loading.fullscreen.lock="fullscreenLoading">
		<div class="flex cofirm_box">
			<button class="cancel" @click="$router.push('/user/AddResource/RedBook')">
				取消
			</button>
			<button v-if="!$route.query.id" class="affirm" @click="addMedia">
				确认
			</button>
			<button v-else class="affirm" @click="updataFn">修改</button>
		</div>
		<div class="flex ct_box">
			<div class="ct_left_box">
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">账户名称：</p>
					<el-input v-model="form.title" placeholder="输入账户名称"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">小红书号：</p>
					<el-input v-model="form.account" placeholder="请输入小红书号"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">资源logo：</p>
					<el-upload v-if="!imageUrl" class="avatar-uploader" @submit="uploadFn()" action="/api/common/upload"
						:headers="headerObj" accept=".jpg,.png,.gif" :show-file-list="false" :limit="1"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<i class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="flex upload_img" v-if="imageUrl">
						<img :src="imageUrl" alt="" style="width: 120px" />
						<!-- 删除图标 -->
						<span class="delete_icon el-icon-delete" @click="beforeMove"></span>
					</div>

					<p style="margin: 4rem 0 0 2rem" v-if="!imageUrl">jpg、png、gif</p>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">行业类型：</p>
					<el-select v-model="form.platform.title" @change="plat" placeholder="选择行业类型">
						<el-option v-for="(item, index) in platList" :key="index" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">所属区域：</p>
					<el-select v-model="form.area.title" @change="areaFn" placeholder="选择受众地区">
						<el-option v-for="(item, index) in areaList" :key="index" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<!-- <div class="flex" style="align-items: center;margin-bottom: 2rem; width: 58%">
					<p class="title_p">所属区域：</p>
					<el-cascader v-model="value" :options="RedareaList" ref="cascaderAddr"
						:props="{ expandTrigger: 'hover', value: 'id', label: 'name', children: 'list', checkStrictly: true }"
						@change="handleChange" placeholder="请选择所属区域">
					</el-cascader>
				</div> -->
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">案例链接：</p>
					<el-input v-model="form.link" placeholder="请输入http:/或https:/完整地址"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">视频价格：</p>
					<el-input v-model="form.video_price" placeholder="请输入视频价格"
						onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
						oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">图文价格：</p>
					<el-input v-model="form.image_text_price" placeholder="请输入图文价格"
						onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
						oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">视频阅读量：</p>
					<el-input v-model="form.video_views" placeholder="请输入视频阅读量"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">图文阅读量：</p>
					<el-input v-model="form.image_text_views" placeholder="输入图文阅读量"></el-input>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p">粉丝数：</p>
					<el-input v-model="form.fans_num" placeholder="请输入粉丝数量"></el-input>
				</div>
				<upLoadImg title="粉丝数截图" :imgUrl="form.fans_image" @getImg="getImg" />

			</div>
			<div class="ct_right_box">
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">受众性别：</p>
					<el-radio v-model="form.audience_sex" :label="0">不限</el-radio>
					<el-radio v-model="form.audience_sex" :label="1">女性粉丝偏多</el-radio>
					<el-radio v-model="form.audience_sex" :label="2">男性粉丝偏多</el-radio>
					<el-radio v-model="form.audience_sex" :label="3">男性粉丝均衡</el-radio>
				</div>
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">受众年龄：</p>
					<el-radio v-model="form.audience_age" :label="0">不限</el-radio>
					<el-radio v-model="form.audience_age" :label="1">0-17岁偏多</el-radio>
					<el-radio v-model="form.audience_age" :label="2">18-24岁偏多</el-radio>
					<el-radio v-model="form.audience_age" :label="3">25-34岁偏多</el-radio>
					<el-radio v-model="form.audience_age" :label="4">35-44岁偏多</el-radio>
					<el-radio v-model="form.audience_age" :label="5">44岁以上偏多</el-radio>
				</div>
				<!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否可发内链：</p>
					<el-radio v-model="form.is_pub_url" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_url" :label="1">是</el-radio>
				</div> -->
				<!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否可发联系方式：</p>
					<el-radio v-model="form.is_pub_contact" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_contact" :label="1">是</el-radio>
				</div> -->
				<specialText />
				<div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否周末可发：</p>
					<el-radio v-model="form.is_pub_weekend" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_weekend" :label="1">是</el-radio>
				</div>

				<!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
					<p class="title_p2">是否可加视频：</p>
					<el-radio v-model="form.is_pub_video" :label="0">否</el-radio>
					<el-radio v-model="form.is_pub_video" :label="1">是</el-radio>
				</div> -->

				<div class="flex">
					<p class="title_p2">备注：</p>
					<el-input type="textarea" :rows="2" placeholder="有修改或其他问题请填写备注，免经济纠纷" v-model="form.remark">
					</el-input>
				</div>
				<p @click="mediumUpload" class="multiple">
					您有多个媒体?请点击此处
				</p>
			</div>
		</div>
		<!-- 批量上传 -->
		<el-dialog title="批量上传媒体" width="35%" :append-to-body="true" :visible.sync="mediumBox" :show-close="false">
			<div style="justify-content: center;align-items: center;text-align: center;" class="flex">
				<el-upload class="upload-demo" accept=".xlsx" :headers="headerObj" drag action="/api/common/upload"
					:on-success="Successxlsx" :before-remove="handleRemove">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">点击上传</div>
				</el-upload>
			</div>

			<div class="flex" style="justify-content: center;margin-top: 1rem;">
				<a class="multiple_a" target="_blank" href="/medium.xlsx">下载模板</a>

				<button class="upLoad_but" @click.stop="upLoadXlsx">确认上传</button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	computed: {
		...mapState(['active'])
	},
	created() {
		this.curlGet('/api/medium/industry').then((res) => {
			if (res.data.code) {
				for (const i in res.data.data[this.$store.state.active]) {
					this.platList.push({
						id: i,
						title: res.data.data[this.$store.state.active][i],
					})
				}
			}
		})
		// 请求门户
		this.curlGet('/api/medium/portal', {
		}).then((res) => {
			if (res.data.code) {
				if (res.data.code) {
					for (const i in res.data.data[this.$store.state.active]) {
						this.gatewayList.push({
							id: i,
							title: res.data.data[this.$store.state.active][i],
						})
					}
				}
			}
		})
		for (let i = 10; i < 25; i++) {
			this.timerList.push({
				id: i,
				title: `${i}:00`,
			})
		}
		// 地区
		this.curlGet('/api/area/list').then((res) => {
			if (res.data.code) {
				for (const i in res.data.data) {
					this.areaList.push({
						id: i,
						title: res.data.data[i].name,
					})
				}
			}
		})
		/*网红地区 */
		this.curlGet('/api/area/list', {
			grade: 2,
		}).then((res) => {
			if (res.data.code) {
				for (const i in res.data.data) {
					this.RedareaList.push(res.data.data[i])
				}
			}
		})
	},
	mounted() {
		this.$store.state.active = 6
		if (this.$route.query.id) {
			this.curlGet('/api/users/medium/detail', {
				id: this.$route.query.id,
			}).then((res) => {
				if (res.data.code) {
					let data = res.data.data
					this.platList.forEach((item, index) => {
						if (data.industry_classify_id == item.id) {
							this.form.platform = {
								id: item.id,
								title: item.title,
							}
						}
					})
					this.areaList.forEach((item, index) => {
						if (data.area_id == item.id) {
							this.form.area = {
								id: item.id,
								title: item.title,
							}
						}
					})
					this.timerList.forEach((item, index) => {
						if (data.stop_draft_time == item.id) {
							this.form.timer = {
								id: item.id,
								title: item.title,
							}
						}
					})

					this.form.account = data.title //账号
					this.form.title = data.title
					this.dialogImageUrl = data.logo //展示logo
					this.imageUrl = data.logo // 向后端发送logo
					this.form.link = data.case_url //案例链接
					this.form.video_price = data.video_price //视频价格
					this.form.video_views = data.video_views //视频播放量
					this.form.image_text_views = data.image_text_views //文章阅读量
					this.form.image_text_price = data.image_text_price //文章价格
					this.form.audience_sex = data.audience_sex //受众性别
					this.form.audience_age = data.audience_age //受众年龄
					// this.form.red_book_city = data.red_book_city //网红所在城市
					// this.form.city_id = data.city_id //网红所在城市(区)
					// this.value = [data.red_book_city, data.city_id]
					this.form.fans_num = data.fans_num //粉丝数
					// this.form.is_pub_url = data.is_pub_url//是否可发链接
					// this.form.is_pub_video = data.is_pub_video //视频
					this.form.fans_image = data.fans_image
					// this.form.is_pub_contact = data.is_pub_contact
					this.form.remark = data.remark //备注
				}
			})
		}
	},
	data() {
		return {
			gatewayList: [], //门户类型
			platList: [], //行业类型
			timerList: [], //截稿时间
			areaList: [], //地区
			RedareaList: [], //网红地区
			value: '',
			dialogImageUrl: '',
			imageUrl: '',
			dialogVisible: false,
			form: {
				title: '',
				is_v_auth: 0, //V认证
				platform: {}, //行业
				image_text_views: '', //浏览数
				account: '', //账户
				timer: {}, //截稿时间
				area: {}, //地址
				red_book_city: '', //网红所在地
				// city_id: '',
				fans_num: '', //粉丝数
				audience_sex: 0, //受众群体
				audience_age: 0, //受众年龄
				video_price: '', //视频价格
				image_text_price: '', //图文价格
				video_views: '', //视频阅读量
				remark: '', // 备注
				link: '', //链接
				fans_image: '',
				is_pub_url: 0,
				is_pub_weekend: 0,
				is_pub_video: 0,
				is_pub_contact: 0,
				mediaUrl: ''

			},
			headerObj: {
				token: this.$user_info.token,
			},
			mediumBox: false,
			fullscreenLoading: false, //加载
			value: '',
		}
	},
	methods: {
		getImg(url) {
			this.form.fans_image = url
		},
		mediumUpload() {
			this.mediumBox = true
		},
		// 批量上传媒体
		Successxlsx(res, file) {
			console.log(res, file)
			this.form.mediaUrl = res.data.url
		},
		upLoadXlsx() {
			this.curlGet('/api/users/medium/import', {
				file: this.form.mediaUrl
			}).then(res => {
				console.log(res);
				if (res.data.code) {
					this.$message({
						message: '上传成功,待审核',
						type: 'success'
					});
					this.form.mediaUrl = ''
					return this.mediumBox = false
				} else {
					this.$message.error('上传失败');
				}
			})
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.form.mediaUrl = ''
		},
		handleAvatarSuccess(res, file) {
			console.log(res)
			this.fullscreenLoading = true
			this.dialogImageUrl = res.data.fullurl

			this.imageUrl = URL.createObjectURL(file.raw)
			setTimeout(() => {
				this.fullscreenLoading = false
			}, 500)
		},
		beforeAvatarUpload(file) {
			let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)

			const extension =
				testmsg === 'jpg' || testmsg === 'gif' || testmsg === 'png'
			const isLt2M = file.size / 1024 / 1024 < 2

			if (!extension) {
				this.$message.error('上传图片类型只能是 JPG,PNG,GIF格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传图片大小不能超过 2MB!')
			}
			return extension && isLt2M
		},
		beforeMove() {
			this.$confirm('是否删除', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.imageUrl = ''
					this.$message({
						type: 'success',
						message: '已删除',
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		gatewayFn(val) {
			let resultArr = this.gatewayList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.gateway = resultArr[0]
		},
		// 获取行业id
		plat(val) {
			let resultArr = this.platList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.platform = resultArr[0]
		},
		// 获取截稿时间id
		timerFn(val) {
			let resultArr = this.timerList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.timer = resultArr[0]
		},
		// 获取地区id
		areaFn(val) {
			let resultArr = this.areaList.filter((item) => {
				return item.title === val
			})
			console.log(resultArr)
			this.form.area = resultArr[0]
		},
		// 网红所在地
		locationFn(val) {

			let resultArr = this.areaList.filter((item) => {
				return item === val
			})
			console.log(resultArr)
			this.form.red_book_city = resultArr[0]
		},
		// handleChange(value) {
		// 	console.log(value);
		// 	this.form.red_book_city = value[0]
		// 	this.form.city_id = value[1]
		// 	// let nodesObj = this.$refs['cascaderAddr'].getCheckedNodes()
		// 	// console.log(nodesObj);
		// 	// console.log(this.form.red_book_city);
		// 	// if (value) {
		// 	// 	console.log(this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels);
		// 	// }
		// },
		// 添加媒体
		addMedia() {
			this.$confirm('确认提交吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.curlPost('/api/users/medium/add', {
						category_id: this.$store.state.active, // 导航id
						account: this.form.account, //账户
						title: this.form.title,
						medium_price: this.form.price,
						fans_num: this.form.fans_num, //粉丝数,
						image_text_price: this.form.image_text_price, //图文价格
						video_price: this.form.video_price, //视频价格
						image_text_views: this.form.image_text_views, //图文阅读量
						video_views: this.form.video_views, //视频阅读量
						case_url: this.form.link, //媒体案例
						industry_classify_id: this.form.platform.id, //媒体行业分类
						area_id: this.form.area.id, //地区
						audience_sex: this.form.audience_sex, // 受众性别
						audience_age: this.form.audience_age, // 受众年纪
						// red_book_city: this.form.red_book_city, //网红所在城市
						// city_id: this.form.city_id, //网红所在城市(区)
						remark: this.form.remark, //备注
						logo: this.imageUrl, // logo
						is_pub_url: this.form.is_pub_url,
						is_pub_video: this.form.is_pub_video,
						is_pub_weekend: this.form.is_pub_weekend,
						is_pub_contact: this.form.is_pub_contact,
						fans_image: this.form.fans_image
					}).then((res) => {
						console.log(res)
						if (res.data.code) {
							this.$message({
								type: 'success',
								message: '添加成功,待审核',
							})
							return this.$router.push('/user/PublishingMedia/pubNews')
						} else {
							return this.$message({
								type: 'warning',
								message: res.data.msg,
							})
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		// 修改媒体
		updataFn() {
			this.$confirm('确认修改吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.curlPost('/api/users/medium/add', {
						id: this.$route.query.id,
						category_id: this.$store.state.active, // 导航id
						account: this.form.account, //账户
						title: this.form.title,
						medium_price: this.form.price,
						fans_num: this.form.fans_num, //粉丝数,
						image_text_price: this.form.image_text_price, //图文价格
						video_price: this.form.video_price, //视频价格
						image_text_views: this.form.image_text_views, //图文阅读量
						video_views: this.form.video_views, //视频阅读量
						case_url: this.form.link, //媒体案例
						industry_classify_id: this.form.platform.id, //媒体行业分类
						area_id: this.form.area.id, //地区
						audience_sex: this.form.audience_sex, // 受众性别
						audience_age: this.form.audience_age, // 受众年纪
						// red_book_city: this.form.red_book_city, //网红所在城市
						// city_id: this.form.city_id, //网红所在城市(区)
						remark: this.form.remark, //备注
						logo: this.imageUrl, // logo
						is_pub_url: this.form.is_pub_url,
						is_pub_video: this.form.is_pub_video,
						is_pub_weekend: this.form.is_pub_weekend,
						is_pub_contact: this.form.is_pub_contact,
						fans_image: this.form.fans_image

					}).then((res) => {
						console.log(res)
						if (res.data.code) {
							this.$message({
								type: 'success',
								message: res.data.msg,
							})
							return this.$router.push('/user/PublishingMedia/pubNews')
						} else {
							return this.$message({
								type: 'warning',
								message: res.data.msg,
							})
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/AddResource.scss';
</style>